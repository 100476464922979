<template>
  <cui-acl  :roles="['HRseeUsersAll', 'HRseeUsersDepartment']" redirect>
    <div class="kit__utils__heading">
      <h5>
        <span class="mr-3">Kontakter</span>
        <a-input placeholder="Søk..." @change="search" v-model="searchText" class="col-md-3" style="float: right;"/>
      </h5>
    </div>
    <div class="row">
      <div class="col-xl-12">
        <a-table :columns="columns" :dataSource="data">
          <a slot="number" slot-scope="text" :href="'tel:'.concat(text)">{{ addSpaces(text) }}</a>
          <a slot="email" slot-scope="text" :href="'mailto:'.concat(text)">{{ text }}</a>
        </a-table>
      </div>
    </div>
  </cui-acl>
</template>
<script>
import cuiAcl from '@/components/cleanui/system/ACL'
import firebase from '@/services/firebase'
import { mapState } from 'vuex'

export default {
  name: 'TablesAntdFilterSorter',
  computed: {
    ...mapState(['user']),
  },
  components: {
    cuiAcl,
  },
  data() {
    return {
      searchText: '',
      data: [],
      oldData: [],
      columns: [
        {
          title: 'Navn',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'Telefon',
          dataIndex: 'phone',
          key: 'phone',
          scopedSlots: { customRender: 'number' },
        },
        {
          title: 'E-post',
          dataIndex: 'email',
          key: 'email',
          scopedSlots: { customRender: 'email' },
        },
        {
          title: 'Avdeling',
          dataIndex: 'department',
          key: 'department',
        },
      ],
    }
  },
  mounted() {
    this.getData()
    this.searchText = ''
  },
  methods: {
    addSpaces(f) {
      // const fVal = f.replace(/\D[^]/g, '')
      // f = fVal.slice(0, 3) + ' ' + fVal.slice(3, 5) + ' ' + fVal.slice(5)
      return f
    },
    search(e) {
      const value = e.target.value
      this.data = this.oldData.filter((data) => JSON.stringify(data).toLowerCase().indexOf(value.toLowerCase()) !== -1)
    },
    getData() {
      this.data = []
      const ref = firebase.firestore.collection('users')
      ref.orderBy('fullName', 'asc')
        .onSnapshot(snapshot => {
          let i = 1
          this.data = []
          snapshot.forEach(doc => {
            // const positions = doc.data().positions
            // const project = positions ? positions.length > 0 ? positions[positions.length - 1].project : '' : ''
            i += i
            this.data.push({
              key: doc.id,
              name: doc.data().fullName,
              email: doc.data().email,
              phone: doc.data().phone,
              department: doc.data().department,
            })
          })
          this.oldData = this.data
        })
    },
  },
}
</script>
